h1 {
  font-family: $primaryFont;
  font-size: 54px;
  font-weight: $bold;
  line-height: 1.4;

  &.white-color {
    color: $white;
  }
  @media (max-width: $mobile) {
    font-size: 40px;
    &.title {
      margin-bottom: 5px;
    }
  }
}

h2 {
  font-family: $primaryFont;
  font-size: 48px;
  font-weight: 600;
  color: $black;
  line-height: 1.4;
  &.title {
    margin-bottom: 40px;
  }
  @media (max-width: $mobile) {
    font-size: 32px;
    &.title {
      padding: 0 20px;
      margin: 10px 0 20px 0;
    }
  }
}

h3 {
  font-family: $primaryFont;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.4;

  &.white-color {
    color: $white;
  }
  @media (max-width: $mobile) {
    font-size: 32px;
    &.sub-title {
      margin-top: 0;
    }
  }
}

h5 {
}

.interior-page {
  h1 {
    text-align: center;
  }
}
