.scroll-down {
  header {
    &.fixed-up.fixed {
      transform: translate3d(0, -100%, 0);
      transition: transform 0.4s ease;
    }
  }
}

.scroll-up {
  header {
    &.fixed-up.fixed {
      transform: none;
      transition: transform 0.4s ease;
    }
  }
}

.header-wrapper {
  position: relative;

  header {
    padding: 35px 0;
    width: 100%;
    background: $white;

    @media (max-width: $tablet) {
      padding: 25px 0;
    }

    @media (max-width: $mobile) {
      padding: 15px 0;
    }

    &.fixed {
      position: fixed;
      top: 0;
      background: $white;
      z-index: 2;
      box-shadow: 0px 5px 20px 0px color($tertiary a(20%));
    }

    .header-container {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      transition: all 0.3s;
      .content-block {
        width: 100%;
        height: 100% !important;
        .header-content {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: all 0.3s;

          .logo {
            display: inline-block;

            @media (max-width: $mobile) {
              max-height: 75px;
            }

            svg {
              @media (max-width: $small) {
                max-width: 250px;
                max-height: 75px;
              }
            }
          }

          ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            list-style-type: none;
            transition: all 0.3s;
            &.desktop-nav {
              display: flex;

              @media (max-width: $tablet) {
                display: none;
              }
            }

            li {
              &::before {
                display: none;
              }

              &:not(.btn) {
                padding-left: 0;
                margin-right: 40px;
                margin-bottom: 0;
                /* padding: 20px 30px; */
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: $light;

                &:last-of-type {
                  margin-right: 0;
                }

                a {
                  padding: 0;
                  color: $darkGray;
                  background: none;
                  letter-spacing: 0.44px;
                  text-transform: uppercase;

                  &:hover,
                  &:active,
                  &:focus {
                    color: $primary;
                  }
                }
              }
            }
          }

          .mobile-nav {
            display: none;
            align-items: center;

            &.always-present {
              display: block;
            }

            @media (max-width: $tablet) {
              display: block;
            }

            .mobile-menu-toggle-button {
              &.active-btn {
                z-index: 2;
                /* svg {
                  &.inactive-menu {
                    display: none;
                  }

                  &.active-menu {
                    display: block;
                  }
                } */
              }

              svg {
                &.inactive-menu {
                  display: block;
                }

                &.active-menu {
                  display: none;
                }

                line {
                  /* stroke: $primary; */
                  stroke: $quadnary;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  display: none;
  width: 100%;
  height: 100vh;
  background: $white;
  visibility: hidden;
  transform: translateX(-100%);
  z-index: 2;
  transition: all 0.7s ease;

  @media (max-width: $tablet) {
    display: block;
  }

  &.open {
    visibility: visible;
    transform: translateX(0);
  }

  .mobile-menu-content {
    background: $primary;
    width: calc(100% - 95px);
    height: 100vh;

    @media (max-width: $mobile) {
      width: calc(100% - 65px);
    }
  }

  .top-container {
    position: relative;
    padding: 35px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $tablet) {
      padding: 25px 0;
    }

    @media (max-width: $mobile) {
      padding: 15px 0;
    }

    .mobile-nav {
      position: absolute;
      right: -60px;

      @media (max-width: $mobile) {
        right: -45px;
      }

      .mobile-menu-toggle-button {
        svg {
          line {
            stroke: $primary;
          }
        }
      }
    }
    @media (max-width: $tiny) {
      a {
        svg {
          &.svg {
            height: 75px;
          }
        }
      }
    }
  }

  .mobile-nav-menu {
    li {
      padding-left: 0;

      &:first-of-type {
        a {
          border-top: 1px solid color($white a(30%));
        }
      }

      &::before {
        display: none;
      }

      &.btn {
        padding: 0;
        margin-top: 50px;

        .nav__link {
          padding: 10px 25px;
          font-size: 16px;
          color: $black;
          background: $white;
          line-height: 1;

          &:hover,
          &:active,
          &:focus {
            background: $altButtonHover;
          }
        }
      }

      a {
        &.nav__link {
          padding: 15px 20px;
          font-size: 28px;
          font-weight: $light;
          display: block;
          color: $white;
          background: none;
          letter-spacing: 0.75px;
          text-transform: uppercase;
          text-align: center;
          border-bottom: 1px solid color($white a(30%));
          font-family: $primaryFont;
          @media (max-width: $tiny) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.hamburger-nav-menu {
  position: fixed;
  top: -100%;
  background: $primary;
  width: 100%;
  height: 100vh;
  visibility: hidden;
  overflow: hidden;
  z-index: 101;
  transition: all 0.5s ease;

  &.open {
    top: 0;
    visibility: visible;
    overflow: auto;
  }

  .top-container {
    position: relative;
    margin: 0 auto;
    padding: 35px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $desktop;

    @media (max-width: $tablet) {
      padding: 25px 20px;
    }

    @media (max-width: $mobile) {
      padding: 15px 20px;
    }

    a {
      &.mobile-menu-toggle-button {
        /* position: absolute;
        top: 35px;
        right: 120px;
        @media (max-width: $tiny) {
          right: 30px;
        } */
      }
    }
  }

  .logo {
    @media (max-width: $small) {
      max-width: 250px;

      svg {
        width: 100%;
      }
    }
  }

  ul {
    &.nav {
      &.header-menu {
        padding-bottom: 25px;
        list-style-type: none;
        width: 100%;
        text-align: center;

        li {
          &::before {
            display: none;
          }
          &.nav__item {
            &:not(.btn) {
              margin: 0;
              padding: 8px;
              border-bottom: 1px solid color($gray a(30%));
              a {
                &.nav__link {
                  color: $white;
                  font-family: $primaryFont;
                  font-size: 28px;
                  text-transform: uppercase;
                  font-weight: $light;
                }
                &:hover,
                &:active,
                &:focus {
                  background: none;
                }
              }
            }
            &.btn {
              a.nav__link {
                margin-top: 10px;
                background: $white;
                color: $black;

                &:hover,
                &:active,
                &:focus {
                  background: $altButtonHover;
                }
              }
            }
          }
          &:first-of-type {
            border-top: 1px solid color($gray a(30%));
          }
        }
      }
    }
  }
}

/* &.hamburger-nav {
  transition: 0.5s ease !important;

  .content-block {
    position: relative;
    max-width: unset;
    margin: 0;
    .header-content {
      position: relative;
      flex-wrap: wrap;
      ul {
        transition: all 0.3s ease;
        &.header-menu {
          width: 100%;
          margin: auto;
          height: 0;
          overflow: hidden;
          max-height: 400px;
        }
        li {
          width: 100%;
          &.nav__item {
            margin-right: 0;
          }
        }
      }
      .mobile-nav {
        display: block;
        top: 0;
        right: 10px;
        position: absolute;
      }
    }
  }
  &.open-hamburger-menu {
    background: $primary;
    height: auto;
    .content-block {
      padding: 0;
      .header-content {
        margin-top: 35px;
        .logo {
          padding-left: 40px;
        }

        .mobile-nav {
          padding-right: 60px;
          a {
            svg {
              line {
                stroke: $white;
              }
            }
          }
        }
      }
    }
  }
} */

/* ul.hamburger-nav-menu {
  height: 100%;
  max-height: 100vh;
  transition: all 0.3s ease;
  margin-bottom: 70px;
  li {
    a {
      &.nav__link {
        color: $white;
        padding: 20px 0;
        border-bottom: 1px solid color($gray a(30%));
      }
    }
    &:first-of-type {
      border-top: 1px solid color($gray a(30%));
    }
  }
} */
