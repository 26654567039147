.flex-grid-container {
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: $mobile) {
    margin-top: 50px;
  }

  .grid-item {
    margin-bottom: 65px;
    margin-right: 65px;
    display: none;
    flex: 1 0 18%;
    max-width: 220px;

    @media (max-width: $tablet) {
      flex: 0 0 35%;
      max-width: 100%;
    }

    @media (max-width: $mobile) {
      margin-bottom: 40px;
      margin-right: 0;
      flex: 0 0 100%;
    }

    &.active {
      display: block;
    }

    &:nth-of-type(2n) {
      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }

    &:nth-of-type(4n),
    &.last-item {
      margin-right: 0;
    }

    .image-container {
      display: flex;
      align-items: center;
      height: 160px;

      @media (max-width: $mobile) {
        height: 200px;
      }

      @media (max-width: $small) {
        height: 160px;
      }

      a {
        padding-bottom: 0;
        background-image: none;
      }

      &.no-image {
        padding: 30px;
        display: block;
        background: $white;
        max-height: 100%;
        text-align: center;
        height: 100%;

        p {
          margin: 0 0 5px;
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0.19px;
          line-height: 1.65;

          &.title {
            color: $primary;
            font-size: 20px;
            font-weight: $bold;
          }

          &.ital {
            font-style: italic;
          }
        }
      }

      &.no-company-image {
        height: 160px;
        background: $gray;
      }

      img {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    .grid-content {
      p {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 20px;
        font-weight: $bold;
        color: $tertiary;
      }
    }
  }

  .full-width {
    flex: 0 0 100%;
  }

  .buttons-container {
    display: flex;
    justify-content: center;

    @media (max-width: $small) {
      flex-direction: column;
    }

    > .btn {
      &.active {
        + .active {
          margin-left: 20px;

          @media (max-width: $small) {
            margin-left: 0;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.grid-container {
  margin: 0 -40px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $heroWidth) {
    flex-direction: column;
  }

  .column {
    padding: 0 40px;
    flex: 1;

    @media (max-width: $formWidth) {
      margin-bottom: 40px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .title {
      margin-bottom: 10px;
    }
  }
}
