.container {
  margin: 0 auto;
  padding: 0 40px;
  max-width: $desktop;
  width: 100%;

  @media (max-width: $mobile) {
    padding: 0 20px;
  }
}

.small-container {
  margin: 0 auto;
  max-width: $tablet;
  width: 100%;

  @media (max-width: $tablet) {
    padding: 0 20px;
  }
}

.mini-container {
  margin: 0 auto;
  max-width: $formWidth;
  width: 100%;
}
