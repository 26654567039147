ul {
  padding-left: 10px;
  margin-bottom: 15px;
  list-style-type: none;

  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 300;
    color: $black;
    letter-spacing: 0.67px;
    line-height: 1.25;

    &::before {
      position: absolute;
      left: 0;
      margin-right: 10px;
      content: '\2013';
      color: $primary;
    }
  }
}

ol {
  position: relative;
  padding-left: 10px;
  margin-bottom: 15px;
  list-style-type: none;
  counter-reset: foundations-ol-style;

  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 300;
    color: $black;
    letter-spacing: 0.67px;
    line-height: 1.25;
    counter-increment: foundations-ol-style;

    &::before {
      position: absolute;
      left: 0;
      margin-right: 10px;
      content: counter(foundations-ol-style) '.';
      font-weight: 300;
      color: $primary;
    }
  }
}
