* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  position: relative;
  overflow-x: hidden;

  &.menu-open,
  &.modal-open {
    overflow: hidden;
  }
}

.body-content > {
  div[class$='container'] {
    padding-top: 50px;
    padding-bottom: 75px;
    @media (max-width: $tablet) {
      padding-top: 25px;
      padding-bottom: 50px;
    }
  }
}

.content-block {
  margin: 0 auto;
  text-align: center;
}

.interior-page {
  padding-top: 55px !important;
  padding-bottom: 200px !important;
  min-height: 100vh;

  @media (max-width: $mobile) {
    padding-top: 30px !important;
    padding-bottom: 100px !important;
  }
}
