.about-container {
    .content-block {
        .text-container#about {
            margin: 0 auto;
            max-width: 955px;
            text-align: center;
            .title {
                margin: 40px 0;
            }
            .content {
                font-size: 24px;
                margin-bottom: 25px;
            }
            .btn.purple {
                margin-top: 35px;
                padding-left: 35px;
                padding-right: 35px;
                &:hover {
                    color: $white;
                }
            }
        }
    }
}