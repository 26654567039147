.fdn-hero-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .overlay {
    position: absolute;
    background: color($primary a(65%));
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .content-block {
    position: relative;

    .hero-content {
      padding: 135px 0 155px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: $heroWidth;
      text-align: center;

      @media (max-width: $mobile) {
        padding: 85px 0 100px;
      }

      .title {
        margin-top: 0;
      }
    }
  }
}
