.team-modal-container {
  display: flex;
  background: color($primary a(90%));
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  visibility: hidden;

  &.modal-open {
    max-height: 3000px;
    opacity: 1;
    z-index: 2;
    visibility: visible;
  }
  .modal-div {
    padding: 35px 45px 35px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: $white;
    max-height: 90%;
    max-width: 600px;
    overflow-y: auto;

    @media (max-width: $mobile) {
      max-height: 90%;
    }

    @media (max-width: $small) {
      margin: 5%;
    }
    p {
      width: 100%;
      &.name {
        margin-bottom: 0;
      }
      &.work-title {
        margin-bottom: 30px;
      }
    }
    .btn-container {
      width: 100%;
      margin-top: 50px;
    }
  }
}
