.swiper-container {
  .swiper-wrapper {
    .swiper-slide {
      .team-member {
        height: 215px;
        width: 175px;
        overflow: hidden;
        border-radius: 10px;
        object-position: 20% 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .name {
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .work-title {
        margin-bottom: 10px;
        font-size: 20px;
        color: $black;
        font-weight: $light;
      }

      .bio {
        color: $primary;
        font-size: 16px;
        font-weight: $bold;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
  }
}
