.error {
  .choices {
    .choices__inner {
      border-color: $red;
    }
  }
}

.error-text {
  margin-top: 5px;
  display: inline-block;
  font-family: $primaryFont;
  color: $red;
}
