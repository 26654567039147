.radio-container {
    padding-left: 0;
    margin-bottom: 0;

    .radio-input {
        padding-left: 0;

        &::before {
            display: none;
        }

        input {
        position: absolute;
        left: -9999px;

        &:checked {
            + label {
                &::before {
                    border-color: $primary;
                }

                &::after {
                    opacity: 1;
                }
            }
        }
    }

    label {
        position: relative;
        margin-bottom: 0;
        padding-left: 30px;
        font-weight: $light;
        display: inline-block;
        cursor: pointer;

        &.error {
            &::before {
                border-color: $red;
            }
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            content: '';
            border: 1px solid $inputGray;
            border-radius: 100%;
            transition: all .3s ease;
        }

        &::after {
            position: absolute;
            top: 4px;
            left: 4px;
            width: 12px;
            height: 12px;
            content: '';
            background: $primary;
            border-radius: 100%;
            opacity: 0;
            transition: all .3s ease;
        }

        &:hover, &:active, &:focus {
            &::before {
                    border-color: $primary;
                }
        }
    }
    }
}
