.cc-window {
  &.cc-banner,
  &.cc-window {
    margin: 0 auto;
    background: $primary;
    max-width: 1440px;
    border-top: none;
    overflow: visible;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      content: '';
      width: 4000px;
      height: 100%;
      background: $primary;
    }

    &::after {
      left: 100%;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    .cc-message {
      margin-right: 60px;
      font-family: $primaryFont;
      font-size: 16px;
      color: $white;

      @media (max-width: $mobile) {
        margin-right: 0;
      }

      a {
        position: relative;
        padding-bottom: 3px;
        font-weight: $bold;
        color: $black;
        background-image: linear-gradient($quadnary, $quadnary);
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 0 2px;
        transition: background-size 0.5s;

        @media (max-width: $mobile) {
          font-size: 16px;
        }

        &:hover,
        &:active,
        &:focus {
          color: $black;
          background-size: 100% 2px;
        }
      }
    }

    .cc-compliance {
      .cc-btn {
        font-family: $primaryFont;
        font-weight: $bold;
        color: $black;
        background: $white;
        border: none;
        border-radius: 20px;
        text-transform: uppercase;
        transition: all 0.3s ease;

        @media (max-width: $mobile) {
          margin-top: 20px;
        }

        &:hover,
        &:active,
        &:focus {
          background: $altButtonHover;
        }
      }
    }
  }
}
