.checkbox-container {
  padding-left: 0;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;

  .checkbox-input {
    margin-bottom: 0;
    padding-left: 0;

    &::before {
      display: none;
    }

    input {
      position: absolute;
      left: -9999px;
      &:checked {
        + label {
          &::before {
            border-color: $primary;
          }

          &::after {
            background-image: url(/site/themes/foundations/img/checkmark.svg);
            opacity: 1;
          }
        }
      }
    }
    label {
      padding-left: 30px;
      position: relative;
      display: inline-block;
      cursor: pointer;
      font-weight: $light;

      &.error {
        &::before {
          border-color: $red;
        }
      }

      &::before {
        content: '';
        position: absolute;
        height: 22px;
        width: 22px;
        border: 1px solid $inputGray;
        top: 0;
        left: 1px;
        transition: all 0.3s ease;
      }
      &::after {
        height: 22px;
        width: 22px;
        background: $primary;
        background-size: 14px 10px;
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        left: 1px;
        top: 1px;
        opacity: 0;
        transition: all 0.3s ease;
      }
      &:hover,
      &:active,
      &:focus {
        &::before {
          border-color: $primary;
        }
      }
    }
  }
}
