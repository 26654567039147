.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $primary;
  min-height: 860px;

  @media (max-width: $mobile) {
    min-height: 600px;
  }

  h1 {
    margin: 0;
    font-size: 200px;
    font-weight: $light;

    @media (max-width: $mobile) {
      font-size: 80px;
    }
  }

  h3 {
    font-size: 72px;
    font-weight: $light;

    @media (max-width: $mobile) {
      font-size: 36px;
    }
  }

  .btn {
    margin-top: 50px;
    display: inline-block;
  }
}
