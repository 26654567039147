.metrics-container {
  background: $black;

  .metrics {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-gap: 65px;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $mobile) {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }

  .metric {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 0;
      color: $white;

      &.number {
        font-size: 70px;
      }

      &.description {
        font-weight: $bold;
      }
    }
  }
}
