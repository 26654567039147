a {
  &.btn {
    padding: 5px 25px;
    font-family: $primaryFont;
    font-weight: $medium;
    color: $white;
    border-radius: 20px;
    text-transform: uppercase;
    transition: all 0.3s ease !important;

    &.primary-color-background {
      background: $primary;
      &.modal-btn-close {
        padding-left: 45px;
        padding-right: 45px;
      }

      &:hover {
        color: $white;
        background: $black;
      }

      &:focus,
      &:active {
        color: $white;
      }
    }

    &.white-color-background {
      color: $black;
      background: $white;

      &:hover,
      &:active,
      &:focus {
        background: $altButtonHover;
      }
    }

    &.error-button {
      padding: 5px 50px;
    }

    &.show-less {
      background: $inputGray;
    }

    /* &.hidden {
      display: none;
    } */

    &.active {
      display: inline-block;
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }
}

.nav {
  .nav__item {
    &.btn {
      > .nav__link {
        padding: 5px 25px;
        display: inline-block;
        font-family: $primaryFont;
        font-weight: $medium;
        color: $white;
        border-radius: 20px;
        text-transform: uppercase;
        transition: all 0.3s ease;
      }
    }

    &.primary-color-background {
      > .nav__link {
        background: $primary;

        &:hover,
        &:active,
        &:focus {
          background: $black;
        }
      }
    }
  }
}

button {
  padding: 5px 25px;
  justify-self: start;
  font-family: $primaryFont;
  font-size: 16px;
  font-weight: $medium;
  color: $white;
  border: none;
  border-radius: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  outline: none;

  &.primary-color-background {
    background: $primary;
  }

  &:hover,
  &:active,
  &:focus {
    color: $white;
    background: $black;
    text-decoration: none;
    cursor: pointer;
  }
}
