.contact-form {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 35px;
  flex-wrap: wrap;

  .title {
    margin-top: 0;
    margin-bottom: 35px;
  }

  form {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px 50px;
    width: 100%;

    @media (max-width: $mobile) {
      grid-template-columns: 1fr;
    }

    .form-field {
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    .full-width {
      grid-column: 1 / -1;
    }
  }
}
