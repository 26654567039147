.swiper-container {
  margin-top: 70px;
  z-index: 0;

  @media (max-width: $mobile) {
    margin-top: 50px;
  }

  .swiper-wrapper {
    /* margin-bottom: 40px; */

    .swiper-slide {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      /* margin-bottom: 60px; */

      .img-container {
        &:not(.team-member) {
          display: flex;
          align-items: center;
          height: 160px;

          > a {
            display: inline-block;
            background-image: none;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }
      }

      p {
        width: 100%;
      }
    }
  }

  .swiper-pagination {
    position: relative;
    margin-top: 60px;
    /* margin: 60px 0 0; */

    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      background: $white;
      border: 2px solid $primary;

      &.swiper-pagination-bullet-active {
        background: $primary;
      }
    }
  }
}
