p {
  margin-bottom: 30px;
  font-family: $primaryFont;
  font-size: 20px;
  font-weight: $light;
  line-height: 1.4;

  @media (max-width: $mobile) {
    font-size: 16px;
    margin-bottom: 20px;
  }

  @media (max-width: $tiny) {
    font-size: 16px;
  }

  &.name,
  &.work-title {
    font-size: 20px;
    color: $primary;
    font-weight: $bold;
    cursor: pointer;
  }

  &.name,
  &.modal-name {
    font-weight: $bold;
    color: $black;
  }

  &.modal-name {
    font-size: 36px;
  }

  &.bio {
    font-size: 18px;
  }

  &.primary-color {
    color: $primary;
  }

  &.secondary-color {
    color: $secondary;
  }

  &.tertiary-color {
    color: $tertiary;
  }

  &.quadnary-color {
    color: $quadnary;
  }

  &.strong {
    font-weight: $bold;
  }

  > strong {
    font-weight: $bold;
  }
}
