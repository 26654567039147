footer {
  padding: 45px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: $black;

  .footer-container {
    display: flex;
    justify-content: space-between;

    @media (max-width: $medDesktop) {
      flex-direction: column;
    }
  }

  .footer-left {
    display: flex;
    flex: 1;
    align-items: center;

    @media (max-width: $tablet) {
      align-items: flex-start;
      flex: auto;
      flex-direction: column;
    }
  }

  p {
    margin: 0;
    display: inline-block;
    font-size: 16px;
    color: $white;
    &.copyright {
      @media (max-width: $tiny) {
        text-align: center;
      }
    }
  }

  .footer-menu {
    margin-left: 20px;
    display: flex;

    @media (max-width: $tablet) {
      margin-top: 20px;
      margin-left: 0;
      flex-direction: column;
      width: 100%;
    }

    @media (max-width: $tiny) {
      margin: 15px auto;
    }

    li {
      position: relative;
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      &::before {
        display: none;
      }

      &:last-of-type {
        &::after {
          display: none;
        }

        a {
          @media (max-width: $tablet) {
            margin-bottom: 0;
          }
        }
      }

      &::after {
        position: relative;
        right: 0;
        display: inline-block;
        height: 16px;
        width: 1px;
        background: $white;
        content: '';

        @media (max-width: $tablet) {
          display: none;
        }
      }
    }

    a {
      position: relative;
      padding: 0;
      margin: 0 15px;
      display: inline-block;
      font-family: $primaryFont;
      font-size: 16px;
      color: $white;

      @media (min-width: $tablet) and (max-width: $medDesktop) {
        margin: 0 10px;
        font-size: 15px;
      }

      @media (max-width: $tablet) {
        margin-bottom: 15px;
        margin-left: 0;
      }

      &::before {
        position: absolute;
        left: 51%;
        right: 51%;
        bottom: -2px;
        content: '';
        height: 1px;
        background: $quadnary;
        transition-property: left, right;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
      }

      &:hover,
      &:active,
      &:focus {
        background: none;

        &::before {
          left: 0;
          right: 0;
        }
      }
    }
  }

  .social-icons {
    display: flex;
    align-items: center;

    @media (max-width: $medDesktop) {
      margin-top: 20px;
    }

    > a {
      display: inline-block;
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover,
      &:active,
      &:focus {
        > svg {
          path {
            fill: $primary;
          }
        }
      }

      > svg {
        transition: all 0.3s ease;

        path {
          fill: $white;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
