.body-content {
  a {
    font-family: $primaryFont;
    &:not(.btn) {
      position: relative;
      padding-bottom: 3px;
      font-size: 20px;
      font-weight: $bold;
      color: $black;
      background-image: linear-gradient($quadnary, $quadnary);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 0 2px;
      transition: background-size 0.5s;

      @media (max-width: $mobile) {
        font-size: 16px;
      }

      &:hover,
      &:active,
      &:focus {
        color: $black;
        background-size: 100% 2px;
      }
    }
  }
}
