select {
  padding-left: 20px;
  font-family: $primaryFont;
  font-size: 16px;
  font-weight: 300;
  color: $tertiary;
  background-color: $white;
  background-image: url(/site/themes/foundations/img/select_arrow.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 25px) center;
  height: 36px;
  border: 1px solid $inputGray;
  border-radius: 20px;
  appearance: none;
}

.choices {
  margin-bottom: 0;
  height: 36px;

  &::after {
    display: none;
  }

  &.is-open {
    .choices__inner {
      background-image: url(/site/themes/foundations/img/select_arrow_open.svg);
    }
  }

  .choices__inner {
    padding: 0 !important;
    display: flex;
    align-items: center;
    background: $white;
    min-height: 100%;
    border-color: $inputGray;
    border-radius: 20px;
    background-image: url(/site/themes/foundations/img/select_arrow.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 25px) center;
    transition: all 0.6s ease;

    .choices__list--single {
      .choices__item {
        padding-left: 20px;
        font-family: $primaryFont;
        font-size: 16px;
        font-weight: 300;
        color: $tertiary;
      }
    }
  }

  .choices__list--dropdown {
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    max-width: calc(100% - 30px);
    max-height: 0;
    border: none;
    overflow: hidden;
    transition: all 0.6s ease;

    &.is-active {
      max-height: 500px;
      transition: all 1.1s ease;
    }

    .choices__item {
      border-left: 1px solid $primary;
      border-right: 1px solid $primary;
      border-bottom: 1px solid $primary;
      font-family: $primaryFont;
      font-size: 16px;
      font-weight: 300;
      color: $tertiary;
      line-height: 1.4;

      &:first-of-type {
        display: none;
      }

      &.is-highlighted {
        background: color($tertiary a(5%));
      }
    }
  }
}
