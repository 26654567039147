input {
  border: 1px solid $inputGray;
  border-radius: 20px;
  height: 36px;
  text-indent: 20px;
  outline: none;
  transition: all 0.3s ease;

  &:active,
  &:focus {
    border-color: $primary;
  }

  &.error {
    border-color: $red;
  }

  &.honeypot {
    display: none;
  }

  &[type='file'] {
    border: none;
    border-radius: 0;
    text-indent: 0;
  }
}
